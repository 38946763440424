import ReportButton from '@/components/UI/ReportButton';
import NavButtons from '@/components/UI/NavButtons';
import NumberInput from '@/components/UI/NumberInput';
import NameInput from '@/components/UI/NameInput';

export default [
    ReportButton,
    NavButtons,
    NumberInput,
    NameInput,
]