<template>
  <input
    :placeholder="placeholder"
    :value="modelValue"
    @input="updateInput"
    type="text"
  />
</template>

<script>
export default {
  name: 'NameInput',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
    }
  }
}
</script>

<style scoped>
</style>
